import Chart from 'chart.js/auto';

window.mrCharts = function (chartData, blockId) {
    var mrChart;
    return {
        blockId: blockId,
        //fields: fieldLabelsAll,
        chartData: {
            //title: fieldLabelsAll[questionId]['fieldLabel'],
            labels: ['Emergente', 'Visitor Ready', 'Market Ready', 'Export Ready'],
            data: chartData,
        },
        renderChart: function () {
            var ctx = document.getElementById('mrChart-' + this.blockId );
            //var len = getCharts.length;

            //for (var i = 0; i < len; i++) {

                //var ctx = getChart;
                var chartType = ctx.dataset.chart;
                //var dataType = eval( ctx.dataset.type );
                //console.log( dataType );

                mrChart = new Chart(ctx, {
                    type: chartType,
                    data: {
                        labels: this.chartData.labels,
                        datasets: [{
                            label: 'Market Ready Profile',
                            data: this.chartData.data,
                            backgroundColor: [
                                'rgba(172, 171, 97, 1)',
                                'rgba(201, 107, 70, 1)',
                                'rgba(44, 66, 91, 1)',
                                'rgba(0, 136, 75, 1)'
                            ],
                            
                            //borderColor: borderColor,
                            borderWidth: 1,
                            hoverOffset: 4,
                            hoverBorderWidth: 0
                        }]
                    },
                    options: {
                        maintainAspectRatio: false
                    }
                });
            //}
        }
    }
}

window.answersChart = function ( questionId, blockId ) {
    var answerChart;
    return {
        blockId: blockId,
        fields: fieldLabelsAll,
        chartData: {
            title: fieldLabelsAll[questionId]['fieldLabel'],
            labels: fieldLabelsAll[questionId]['labels'],
            data: fieldLabelsAll[questionId]['counts'],
        },
        renderChart: function() {

            var ctx = document.getElementById('answersChart-' + this.blockId);
            var chartType = ctx.dataset.chart;

            answerChart = new Chart(ctx, {
                type: chartType,
                data: {
                    labels: this.chartData.labels,
                    datasets: [{
                        label: this.chartData.title,
                        data: this.chartData.data,
                        backgroundColor: [
                            'rgba(172, 171, 97, 1)',
                            'rgba(201, 107, 70, 1)',
                            'rgba(44, 66, 91, 1)',
                            'rgba(0, 136, 75, 1)'
                        ],

                        //borderColor: borderColor,
                        borderWidth: 1,
                        hoverOffset: 4,
                        hoverBorderWidth: 0
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    scales: {
                        x: {
                            ticks: {
                                stepSize: 1
                            }
                        }
                    }
                },
                plugins: [{
                    beforeInit: function (chart) {
                        chart.data.labels.forEach(function (value, index, array) {
                            // var a = [];
                            // a.push(value.slice(0, 50));
                            // var i = 1;
                            // while (value.length > (i * 50)) {
                            //     a.push(value.slice(i * 50, (i + 1) * 50));
                            //     i++;
                            // }
                            // array[index] = a;
                            if (value.length > 50) {
                                array[index] = value.substring(0, 50) + '...';
                            }
                        })
                    }
                }]
            });
        },
        selectQuestion: function (index) {
            this.chartData.title = fieldLabelsAll[index]['fieldLabel'],
            this.chartData.labels = fieldLabelsAll[index]['labels'];
            this.chartData.data = fieldLabelsAll[index]['counts'];
            answerChart.destroy();
            this.renderChart();
        },
    }
}


var ctx2 = document.getElementById('companyChart');
if ( ctx2 ) {
    var myChart = new Chart(ctx2, {
        type: 'radar',
        data: {
            labels: ['Empresarial/Business', 'Comercial/Commerical', 'Operativa/Operations', 'Buenas Práctias/Sustainability'],
            datasets: [{
                label: 'Modelo Market Ready/Market Readiness',
                data: companyChartData,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: ['#c6c444', '#e67c37', '#084a6e', '#0c8241'],
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)',
                pointRadius: 9
            }]      
        },
        options: {
            scales: {
                r: {
                    beginAtZero: true,
                    suggestedMin: 0,
                    suggestedMax: 4,
                    ticks: {
                        stepSize: 1
                    }
                }
            },
            elements: {
                line: {
                    borderWidth: 3
                }
            },
            plugins: {
                legend: {
                    display: true, 
                    // labels: {
                    //     color: 'rgb(255, 99, 132)'
                    // }
                }
            }
        }
    });
}