import { Gauge } from "gaugeJS";

//jQuery(document).ready(function ($) {

    var opts1 = {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.3, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
            length: 0.5, // // Relative to gauge radius
            strokeWidth: 0.1, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: false, // If false, max value increases automatically if value > maxValue
        limitMin: false, // If true, the min value of the gauge will be fixed
        colorStart: '#ffff00', // Colors
        colorStop: '#edd900', // just experiment with them
        strokeColor: '#edd900', // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true, // High resolution support
        staticZones: [{
            strokeStyle: "#e5880d",
            min: 0,
            max: 1
        },
        {
            strokeStyle: "#e21216",
            min: 1.0,
            max: 2
        },
        {
            strokeStyle: "#084A6E",
            min: 2.0,
            max: 3
        },
        {
            strokeStyle: "#108241",
            min: 3.0,
            max: 4
        },
        ],
    };

    var opts2 = {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.3, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
            length: 0.5, // // Relative to gauge radius
            strokeWidth: 0.1, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: false, // If false, max value increases automatically if value > maxValue
        limitMin: false, // If true, the min value of the gauge will be fixed
        colorStart: '#f08307', // Colors
        colorStop: '#f08307', // just experiment with them
        strokeColor: '#f08307', // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true, // High resolution support
        staticZones: [{
            strokeStyle: "#e5880d",
            min: 0,
            max: 1
        },
        {
            strokeStyle: "#e21216",
            min: 1.0,
            max: 2
        },
        {
            strokeStyle: "#084A6E",
            min: 2.0,
            max: 3
        },
        {
            strokeStyle: "#108241",
            min: 3.0,
            max: 4
        },
        ],
    };

    var opts3 = {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.3, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
            length: 0.5, // // Relative to gauge radius
            strokeWidth: 0.1, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: false, // If false, max value increases automatically if value > maxValue
        limitMin: false, // If true, the min value of the gauge will be fixed
        colorStart: '#1611ba', // Colors
        colorStop: '#1611ba', // just experiment with them
        strokeColor: '#1611ba', // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true, // High resolution support
        staticZones: [{
            strokeStyle: "#e5880d",
            min: 0,
            max: 1
        },
        {
            strokeStyle: "#e21216",
            min: 1.0,
            max: 2
        },
        {
            strokeStyle: "#084A6E",
            min: 2.0,
            max: 3
        },
        {
            strokeStyle: "#108241",
            min: 3.0,
            max: 4
        },
        ],
    };

    var opts4 = {
        angle: 0, // The span of the gauge arc
        lineWidth: 0.3, // The line thickness
        radiusScale: 1, // Relative radius
        pointer: {
            length: 0.5, // // Relative to gauge radius
            strokeWidth: 0.1, // The thickness
            color: '#000000' // Fill color
        },
        limitMax: false, // If false, max value increases automatically if value > maxValue
        limitMin: false, // If true, the min value of the gauge will be fixed
        colorStart: '#0fa316', // Colors
        colorStop: '#0fa316', // just experiment with them
        strokeColor: '#0fa316', // to see which ones work best for you
        generateGradient: true,
        highDpiSupport: true, // High resolution support
        staticZones: [{
            strokeStyle: "#e5880d",
            min: 0,
            max: 1
        },
        {
            strokeStyle: "#e21216",
            min: 1.0,
            max: 2
        },
        {
            strokeStyle: "#084A6E",
            min: 2.0,
            max: 3
        },
        {
            strokeStyle: "#108241",
            min: 3.0,
            max: 4
        },
        ],
    };

    var target1 = document.getElementById('canvas-preview1'); // your canvas element
    if (target1) {
        var preview1 = new Gauge(target1).setOptions(opts1); // create sexy gauge!
        preview1.maxValue = 4; // set max gauge value
        preview1.setMinValue(0); // Prefer setter over gauge.minValue = 0
        preview1.animationSpeed = 100; // set animation speed (32 is default value)
        preview1.set(companyChartData.at(0)); // set actual value
    }

    var target2 = document.getElementById('canvas-preview2'); // your canvas element
    if (target2) {
    var preview2 = new Gauge(target2).setOptions(opts2); // create sexy gauge!
    preview2.maxValue = 4; // set max gauge value
    preview2.setMinValue(0); // Prefer setter over gauge.minValue = 0
    preview2.animationSpeed = 100; // set animation speed (32 is default value)
    preview2.set(companyChartData.at(1)); // set actual value
    }

    var target3 = document.getElementById('canvas-preview3'); // your canvas element
    if (target3) {
    var preview3 = new Gauge(target3).setOptions(opts3); // create sexy gauge!
    preview3.maxValue = 4; // set max gauge value
    preview3.setMinValue(0); // Prefer setter over gauge.minValue = 0
    preview3.animationSpeed = 100; // set animation speed (32 is default value)
    preview3.set(companyChartData.at(2)); // set actual value
    }

    var target4 = document.getElementById('canvas-preview4'); // your canvas element
    if (target4) {
    var preview4 = new Gauge(target4).setOptions(opts4); // create sexy gauge!
    preview4.maxValue = 4; // set max gauge value
    preview4.setMinValue(0); // Prefer setter over gauge.minValue = 0
    preview4.animationSpeed = 100; // set animation speed (32 is default value)
    preview4.set(companyChartData.at(3)); // set actual value
    }

    // var counters = $(".count");
    // var countersQuantity = counters.length;
    // var counter = [];

    // for (i = 0; i < countersQuantity; i++) {
    //     counter[i] = parseInt(counters[i].innerHTML);
    // }

    // var count = function (start, value, id) {
    //     var localStart = start;
    //     setInterval(function () {
    //         if (localStart < value) {
    //             localStart++;
    //             counters[id].innerHTML = "+" + localStart;
    //         }
    //     }, 50);
    // }

    // for (j = 0; j < countersQuantity; j++) {
    //     count(0, counter[j], j);
    // }
//});