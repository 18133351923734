import { MarkerClusterer } from "@googlemaps/markerclusterer";

function initMap( mapDiv ) {
    
    map = new google.maps.Map(mapDiv, {
        //center: { lat: 24.53479887554392, lng: -102.56352424341667 },
        zoom: 10
    });

    const markers = locations.map((location, i) => {
        const amt = String(location.lng + ' ' + location.lat)

        // Create info window.
        const infowindow = new google.maps.InfoWindow({
            content: '<div class="text-base font-bold">' + location.company + '</div>'
        });

        const marker = new google.maps.Marker({
            position: {
                lat: location.lat,
                lng: location.lng,
            },
            title: location.company
        });

        // Show info window when marker is clicked.
        marker.addListener("click", () => {
            infowindow.open({
                anchor: marker,
                map,
            });
        });

        return marker;
    });


    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ map, markers });

    // Center map based on markers.
    centerMap( map, markers );

}

function centerMap( map, markers ) {

    // Create map boundaries from all map markers.
    
    var bounds = new google.maps.LatLngBounds();
    markers.forEach(function (marker) {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });
    
    // Case: Single marker.
    if (markers.length == 1) {
        map.setCenter(bounds.getCenter());

        // Case: Multiple markers.
    } else {
        //google.maps.event.addListenerOnce(map, 'idle', function () {
        map.fitBounds(bounds);
        //});
    }
    

}

let map;

document.addEventListener("DOMContentLoaded", () => {
    //const button = document.querySelector('#Ubicaciones');
    //button.addEventListener('click', (event) => {
        //setTimeout(function () {
            var mapDiv = document.getElementById("mrMap");

            if (mapDiv) {
                map = initMap(mapDiv);
            }
        //}, 500);
    //})
})
