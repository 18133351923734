window.setup = function () {
    var getTabs = document.getElementsByClassName( 'amt-tab-item' );
    var len = getTabs.length;
    var tabsList = [];
    for ( var i = 0; i < len; i++ ) {
        var activeTab = 'activeTab===' + i;
        getTabs[i].setAttribute('x-show', activeTab);
        var tabName = getTabs[i].getElementsByClassName('tab-title')[0].innerText;
        tabsList[i] = tabName;
    }
    return {
        activeTab: 0,
        tabs: tabsList
    }
}