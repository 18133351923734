import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
//import persist from '@alpinejs/persist';
import './components/datatables';
//import './components/companyChart';
import './components/tabs';
import './components/charts-new';
import './components/slider';
import './components/gmaps';
import './components/empresasMap';
import './components/gauge';

// window.onload = function () {
//     Alpine.plugin(persist);
//     window.Alpine = Alpine;
//     Alpine.start();
// }
Alpine.plugin(collapse);
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();