import { MarkerClusterer } from "@googlemaps/markerclusterer";

function initMap( mapDiv ) {
    
    map = new google.maps.Map(mapDiv, {
        //center: { lat: 24.53479887554392, lng: -102.56352424341667 },
        zoom: 10
    });

    var infowindow = new google.maps.InfoWindow({
        content: "",
        //maxWidth: 235,
    });

    const markers = locations.map((location, i) => {
        // const contentString = `
        //     <div class="company-infowindow">
        //         <a href="${location.slug}" class="company-link">
        //             <span class="infowindow-overlay"></span>
        //             <img src="${location.image}" class="location-image">
        //             <h3 class="location-name">${location.name}</h3>
        //             <a href="${location.slug}" class="company-button">Ver Empresa <svg aria-hidden="true" role="img" height="1em" width="1em" viewBox="0 0 256 512" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></a>
        //         </a>
        //     </div>
        // `;

        const contentString = `
            <div class="">
                <a href="${location.slug}" class="text-base font-bold">
                    <h3 class="">${location.name}</h3>
                </a>
            </div>
        `;

        const marker = new google.maps.Marker({
            position: {
                lat: location.lat,
                lng: location.lng
            },
            //label: labels[i % labels.length],
            title: location.name,
        });

        marker.addListener("click", () => {
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
        });

        return marker;
    });

    // close info window when map is clicked
    map.addListener('click', () => {
        infowindow.close();
    });

    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({map, markers});

    // Center map based on markers.
    centerMap( map, markers );

}

function centerMap( map, markers ) {

    // Create map boundaries from all map markers.
    
    var bounds = new google.maps.LatLngBounds();
    markers.forEach(function (marker) {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng()
        });
    });
    
    // Case: Single marker.
    if (markers.length == 1) {
        map.setCenter(bounds.getCenter());

        // Case: Multiple markers.
    } else {
        //google.maps.event.addListenerOnce(map, 'idle', function () {
        map.fitBounds(bounds);
        //});
    }
    

}

var mapDiv = document.getElementById("empresasMap");
let map;

if ( mapDiv ) {
    map = initMap( mapDiv );
}
