// core version + navigation, pagination modules:
import SwiperCore, { Navigation } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation]);

// init Swiper:
const swiper = new SwiperCore('.swiper-container', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 20,
    centeredSlides: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
